import { PolicyBase } from '../base/policy-base.model';
import { Dwelling } from './dwelling.model';

export class PropertyPolicy extends PolicyBase {

    public dwellings: Dwelling[];
    public crimePart: any;
    public propertyPart: any;
    public generalLiabilityPart: any;
    public inlandMarinePart: any;
    public terrorismPremium: number;
    public membershipName?:string;
    public plLocation?:any;
    public pricipleTypeofFarmingDes?:string;
    public occupancyDes?:string;
    public farmExposure?:boolean;
    public propertyPolicyTypeCd?:string;
    public preferredMethodOfCorrespondDesc?:string;

    constructor(init?: Partial<PropertyPolicy>) {
        if (!init) { init = {}; }
        super(init);
        this.dwellings = init.dwellings || new Array<Dwelling>();
        this.crimePart = init.crimePart || null;
        this.propertyPart = init.propertyPart || null;
        this.generalLiabilityPart = init.generalLiabilityPart || null;
        this.inlandMarinePart = init.inlandMarinePart || null;
        this.terrorismPremium = init.terrorismPremium || null;
    }
}
